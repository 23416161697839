export const pageData = {
  title: '2020 Top 10 Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '2020 Top 10 Real Estate Websites',
      url: '/top-10-real-estate-websites/2020',
    },
  ],
  uri: '/top-10-real-estate-websites/2020',
}

export const websiteTexts = [
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'The superstars of Netflix’s “Selling Sunset” are masters when it comes to marketing Los Angeles’ most exclusive high-end properties. Their website, OGroup.com, pulls out all the stops by creating a well-rounded visual package that seamlessly combines crisp images, snappy animations, clever use of parallax effects, and terrific balance between design elements and content.',
  },
  {
    title: 'Quiana \nWatson',
    subtitle: 'Atlanta, GA',
    description:
      'Quiana Watson gets up close and personal at QuianaWatson.com with a detailed About section that more than persuades top-tier clients to hire Atlanta’s top real estate professional. The site’s nifty Calendly feature helps clients reach out and schedule a consultation with the click of a button.',
  },
  {
    title: 'Chad \nCarroll',
    subtitle: 'Miami, FL',
    description:
      'Representing the most distinctive properties in South Florida and New York, Chad Carroll is a professional with a wealth of experience not only in real estate, but in finance, contracts, and negotiation as well – an area “power broker” in every sense. His team’s website, TheChadCarrollGroup.com, is a gateway to the luxurious and laidback lifestyle, offering a smooth user experience through its refreshing teal and white color scheme, innovative use of image sliders, and well-organized layout.',
  },
  {
    title: 'Dolly \nLenz',
    subtitle: 'Los Angeles, CA',
    description:
      'Aaron Kirman specializes in Los Angeles’ luxury real estate market, and is the go-to resource for clientele in search of the luxury lifestyle. His website showcases the finest properties in style, using a polished black and white theme and an easy to navigate interface. Stylish, smooth, and straightforward.',
  },
  {
    title: 'Aaron \nKirman',
    subtitle: 'Los Angeles, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'Bobby \nBoyd',
    subtitle: 'Los Angeles, CA',
    description:
      'You need the very best at your side when you’re navigating the affluent Los Angeles real estate market. You know you’ve found the right man for the job as soon as you visit BobbyBoydRE.com. Sophisticated and oozing with personality, premier realtor Bobby Boyd’s website offers a glimpse of the luxurious Los Angeles lifestyle and some of the most gorgeous properties available.',
  },
  {
    title: 'The Altman \nBrothers',
    subtitle: 'Los Angeles, CA',
    description:
      'The Altman Brothers deliver elite real estate services that befit their upscale clientele. The image-driven listings section helps buyers navigate the Beverly Hills luxury real estate market with ease. The “Million Dollar Los Angeles” stars’ black-and-gold theme is elegant and classic. Meanwhile, the subtle application of their logo in the background further strengthens this dynamic duo’s brand.',
  },
  {
    title: 'Sally Forster \nJones',
    subtitle: 'Beverly Hills, CA',
    description:
      'Sally Forster Jones goes for casual elegance with a clean white and gold palette. The listings section showcases Beverly Hills luxury real estate with both aerial and interior shots of luxury properties. With more than $7 billion in total sales, SallyForsterJones.com has been featured on Forbes, CNN Money, and Los Angeles Times.',
  },
  {
    title: 'Ernie \nCarswell',
    subtitle: 'Beverly Hills, CA',
    description:
      'CarswellAndAssociates.com has mastered “The Art of Representing You” by keeping the focus on clients’ needs and putting Beverly Hills real estate front and center with a prominent listings section. The site also showcases a map of active and sold properties in their area to give clients a quick peek at the luxury market.',
  },
  {
    title: 'Sharlene \nChang',
    subtitle: 'West Hollywood, CA',
    description:
      'SharleneChang.com is “Turning Dreams into Reality” with a user-friendly search feature that helps clients find their dream home based on square footage, number of rooms, and other details. A dynamic map showing active and pending listings throughout their service area also gives clients a feel for the luxury market.',
  },
]
